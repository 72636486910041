:root {
    --white: #000000;
    --black: #FFFFFF;
    --panel-color: #f9f9f9;
    --button-color: #f1f1f1;
    --item-border: rgba(0, 0, 0, 0.1);
    --item-radius: 10px;
    --md-8: 8px;
    --md-14: 14px;
    --md-16: 16px;
    --md-20: 20px;
    --md-24: 24px;
    --md-32: 32px;
    --md-48: 48px;
    --md-64: 64px;
    --md-72: 72px;
    --md-96: 96px;
}

html {
    height: 100%;
    /* overflow-x: hidden; */
}

#root {
    height: 100%;
}

.navbar-container {
    padding-right: var(--md-24);
    padding-left: var(--md-24);
    padding-top: var(--md-24);
    padding-bottom: var(--md-24);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    display: flex;
    justify-content: left;
    align-items: center;
    top: 0px;
    z-index: 100;
}
h3{
    margin-top: 8px;
    margin-bottom: 4px;
}
.centered-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.centered-expanding-row {
    display: flex;
    justify-content: stretch;
    align-items: center;
    justify-content: center;
}

.centered-expanding-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wide-col {
    width: 100%;
    /* overflow-x: hidden; */
}

.left-col {
    display: flex;
    flex-direction: column;
    align-self: baseline;
    margin-left: var(--md-64);
}

body {
    background-color: var(--black);
    height: 100%;
}

h1.hero {
    font-size: var(--md-64);
    letter-spacing: -0.64px;
}

h1 {
    color: var(--white);
    font-size: var(--md-48);
    font-weight: bolder;
    margin: 0px;
}

h2 {
    color: var(--black);
    font-size: var(--md-24);
    font-weight: 600;
    margin: 0px
}

h2.black {
    color: var(--white);
    font-size: var(--md-24);
}

h2.hero {
    font-size: var(--md-32);
}

h4 {
    color: var(--black);
    font-size: var(--md-20);
    font-weight: bold;
    margin: 0px;
}

h5 {
    color: var(--black);
    font-size: var(--md-16);
    font-weight: bold;
    margin: 0px;
}

p {
    color: var(--white);
    margin: 0px;
    font-size: var(--md-16);
}

.large {
    color: var(--white);
    margin: 0px;
    font-size: var(--md-20);
}

a {
    color: var(--white);
    text-decoration: none;
}
.youtube {
    width:420;
    height:420
}
@media (max-width: 751px) {
    h1.hero {
        font-size: var(--md-48);
    }

    h1 {
        font-size: var(--md-32);
    }

    h2 {
        font-size: var(--md-20);
    }

    h4 {
        font-size: var(--md-16);
    }

    .large {
        font-size: var(--md-16);
    }

    p {
        font-size: var(--md-14);
    }
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-16 {
    margin-top: 16px;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-top-32 {
    margin-top: 32px;
}

.margin-top-48 {
    margin-top: 48px;
}

.margin-top-64 {
    margin-top: 46px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-bottom-32 {
    margin-bottom: 32px;
}

.margin-bottom-48 {
    margin-bottom: 48px;
}

.margin-bottom-64 {
    margin-bottom: 64px;
}

.margin-right-16 {
    margin-right: 16px;
}

.margin-left-32 {
    margin-left: 32px;
}

p.quiet {
    opacity: 0.6;
}

h2.quiet {
    opacity: 0.6;
    color: var(--white);
}

.black {
    color: var(--white);
}

.gray {
    color: var(--gray);
}

.normal {
    font-weight: normal;
}

.center {
    text-align: center;
}

.panel {
    border-radius: var(--item-radius);
    background-color: var(--panel-color);
}

.call-to-action-button {
    display: flex;
    background-color: var(--white);
    border-radius: var(--md-16);
    height: var(--md-72);
    width: 342px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

@media (max-width: 751px) {
    .call-to-action-button {
        max-width: calc(100vw - var(--md-16) * 2);
    }
}

.big-button {
    display: flex;
    background-color: var(--white);
    border-radius: 16px;
    height: 48px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding-top: var(--md-8);
    padding-bottom: var(--md-8);
    padding-left: var(--md-64);
    padding-right: var(--md-64);
}

@media (max-width: 751px) {
    .big-button {
        height: 40px;
    }
}

.back-button {
    display: flex;
    background-color: var(--white);
    border-radius: 10px;
    height: 15px;
    width: 15px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding-top: var(--md-8);
    padding-bottom: var(--md-8);
    padding-left: var(--md-8);
    padding-right: var(--md-8);
    align-self: left;
}

.big-button:hover {
    opacity: 0.8;
}

.big-button:disabled {
    opacity: 0.6;
}


#admin-reporting {
    position: relative;
    border-radius: 2px;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    align-self: self-end;
    justify-content: center;
    align-items: center;
    color: var(--gray);
    margin-bottom:-25px;
    margin-right:-25px;
    
}

textarea {
    background-color: var(--button-color);
    border: 3px solid var(--button-color);
    border-radius: var(--item-radius);
    padding: var(--md-16);
    font-size: inherit;
    color: var(--white);
    font-family: inherit;
    min-height: 80px;
    resize: vertical;
}

textarea:focus {
    outline: none;
    border: 3px solid var(--item-border);
}

input {
    background-color: var(--button-color);
    border: 3px solid var(--button-color);
    border-radius: var(--item-radius);
    padding: var(--md-16);
    font-size: inherit;
    color: var(--white);
    font-family: inherit;
}

input:focus {
    outline: none;
    border: 3px solid var(--item-border);
}

.link {
    text-decoration: underline;
}

.logo-container {
    position: absolute;
    left: var(--md-24);
    top: var(--md-24);
    z-index: 100;
}

@media (max-width: 510px) {
    .logo-container {
        position: relative;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: var(--md-24);
        padding-bottom: var(--md-32);
    }
    h1.hero {
        padding-top: 32px;
    }
    h2.hero {
        padding-top:8px
    }
}