img.hero-image {
    height: calc(max(min(60vh, 70vw), 225px));
}

.hero-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: clip;
    margin-top: -10vh;
    margin-bottom: -10vh;
    width: 100vw;
    z-index: -1;
}

img.step-image {
    height: calc(min(244px, 45vw));
}

.step-image-container {
    display: flex;
    align-items: center;
}

.left-col.offset {
    margin-bottom: var(--md-64);
    max-width: 625px;
}

@media (max-width: 751px) {
    .left-col.offset {
        margin-left: var(--md-16);
        margin-right: var(--md-16);
        align-self: stretch;
        align-items: center;
        text-align: center;
        max-width: 100%;
    }

    #tagline {
        margin-right: var(--md-48);
        margin-left: var(--md-48);
    }

    #call-to-action-container {
        flex-direction: column;
    }
}

.scroll-container {
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: scroll;
}

section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start start;
    scroll-snap-stop: always
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
    margin-bottom: 180px;
}

.banner {
    background-color: var(--button-color);
    padding-top: var(--md-96);
    padding-bottom: var(--md-96);
    padding-left: var(--md-64);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 751px) {
    .banner {
        align-items: center;
        padding-left: var(--md-64);
        padding-right: var(--md-64);
    }

    .pro-container {
        text-align: center;
    }
}

.clear-banner {
    padding-top: var(--md-96);
    display: flex;
    align-items: center;
    justify-content: center;
}

.demo-video-button{
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
}

.intro-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.report-container {
    padding: var(--md-32);
    max-width: 480px;
    border-radius: 16px;
    margin-bottom: var(--md-32);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.how-to-width {
    max-width: 480px;
}

.process-container {
    padding-left: var(--md-20);
    padding-right: var(--md-20);
    padding-bottom: var(--md-48);
}

#learn-more-text {
    flex: 1;
    text-align: center;
}