.footer-container {
    background-color: var(--white);
    padding-top: var(--md-64);
    padding-bottom: var(--md-64);
    padding-left: var(--md-96);
    color: var(--black);
}

.footer-col-container {
    display: flex;
    flex-direction: row;
    gap: var(--md-48);
    flex-wrap: wrap;
}

.footer-footer-container {
    margin-top: var(--md-32);
}

.footer-col {
    display: flex;
    flex-direction: column;
    gap: var(--md-8);
}

.col-header {
    margin: var(--md-8);
    margin-left: 0px;
}

.col-text {
    color: var(--black);
}

@media screen and (max-width: 480px) {
    .footer-container {
        padding-left: var(--md-32);
    }
}




