.map-container {
    min-height: 300px;
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  font-size: 48px;
}

#my-location-container {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    color: var(--white);
    right: 8px;
    top: 8px;
}

.centered-pin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: auto;
    -webkit-user-select: none;
    margin: 0px
}

.form-centering-container {
  margin-top: var(--md-32);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-container {
  width: 70%;
  max-width: 520px;
  padding: var(--md-48);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-container.center {
  align-items: center;
}

.color-red{
  color:red;
}
.color-blue{
  color:#3366CC;
}



@media screen and (max-width: 480px) {

  .form-container {
    width: 100vw;
    box-sizing: border-box;
    padding: var(--md-32);
    overflow: hidden;
  }

  .form-centering-container {
    margin-top: 0px;
    padding-left: var(--md-32);
    padding-right: var(--md-32);
  }
}

.vertical-line {
  position: absolute;
  top: 0px;
  left: 50%;
  height: calc(100% - 1px);
  width: 0px;
  border: 0.5px dashed var(--white);
}

.horizontal-line {
  position: absolute;
  top: 50%;
  left: 0px;
  width: calc(100% - 1px);
  height: 0px;
  border: 0.5px dashed var(--white);
}

.dot {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: var(--white);
}

.page-container {
  min-height: 100vh;
}

.step-index-container {
  display: flex;
  flex-direction: row;
  gap: var(--md-16);
  align-items: center;
}